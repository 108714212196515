$(function () {
    $('.cc-input').on('focus', function () { this.select() });

    $.fn.commonAncestor = function () {
        if (!this.length)
            return null;

        var parent = this[0].parentNode;
        for (var i = this.length - 1; i >= 1; --i) {
            var elt = this[i];
            if (elt.contains(parent))
                parent = elt.parentNode;
            else
                while (!parent.contains(elt))
                    parent = parent.parentNode;
        }
        return parent;
    };

    var modules = $('[ng-app*="currencyCalculator"]');

    if (modules.length > 1) {
        for (var i = 0; i < modules.length; i++) {
            modules[i].removeAttribute('ng-app');
        }
        modules.commonAncestor().setAttribute('ng-app', 'currencyCalculator');
    }
});

if (typeof (angular) !== 'undefined') {
    angular.module('currencyCalculator', []);

    angular.module('currencyCalculator').controller('currencyCalculatorController', ['$scope', '$http', '$filter', function ($scope, $http, $filter) {
        $scope.fractionSize = function (value) {
            /*
            if (typeof value === 'number') {
                if (value < 0.001 && value >= 0.0001)
                    return 4;
                if (value < 0.1 && value >= 0.001)
                    return 3;
            }
            */
            return 2;
        }

        $scope.loading = true;
        $http.get('/api/exchangerates/calculator?language=' + langidpage)
            .then(function successCallback(response) {
                $scope.lastUpdate = response.data.UpdateTime;
                $scope.rates = response.data.Currencies;
                $scope.dataOK = (typeof $scope.rates !== 'undefined' && $scope.rates !== null && Object.keys($scope.rates).length > 0 && typeof $scope.lastUpdate !== 'undefined' && $scope.lastUpdate !== null && $scope.lastUpdate !== 0);
            }, function errorCallBack() {
                $scope.loading = false;
                $scope.dataOK = false;
            })
            .then(function () {
                if ($scope.dataOK) {
                    $scope.loading = false;
                    $scope.eurosAmount = 1.00;
                    $scope.selectedCurrency = $scope.rates.USD;
                    $scope.selCurrCode = $scope.selectedCurrency.code;
                    var result = $scope.eurosAmount * $scope.selectedCurrency.rate;
                    $scope.otherCurrencyAmount = $filter('number')(result, $scope.fractionSize(result));
                    $scope.wasToEur = false;

                    $scope.convert = function (inputValid, isToEur) {
                        $scope.selCurrCode = $scope.selectedCurrency.code;
                        if (isToEur) {
                            if (!inputValid) return;

                            if (typeof $scope.otherCurrencyAmount === 'undefined' || $scope.otherCurrencyAmount === null) {
                                $scope.eurosAmount = 0;
                                $scope.otherCurrencyAmount = 0;
                            } else {
                                var result = $filter('localizedFloat')($scope.otherCurrencyAmount) * (1 / $scope.selectedCurrency.rate);
                                $scope.eurosAmount = $filter('number')(result, $scope.fractionSize(result));
                            }
                            $scope.wasToEur = true;
                        } else {
                            $scope.selCurrCode = $scope.selectedCurrency.code;
                            if (!inputValid) return;

                            if (typeof $scope.eurosAmount === 'undefined' || $scope.eurosAmount === null) {
                                $scope.eurosAmount = 0;
                                $scope.otherCurrencyAmount = 0;
                            } else {
                                var result = $filter('localizedFloat')($scope.eurosAmount) * ($scope.selectedCurrency.rate);
                                $scope.otherCurrencyAmount = $filter('number')(result, $scope.fractionSize(result));
                            }
                            $scope.wasToEur = false;
                        }
                    }
                    $scope.changeDirection = function () {
                        $scope.wasToEur = !$scope.wasToEur;
                    }
                }
            });
    }]);

    angular.module('currencyCalculator').filter('localizedFloat', function () {
        return function (value) {

            var FLOAT_REGEXP_1 = /^\d{1,3}(\.\d{3})*(\,\d+)?$/; //  67.123,45
            var FLOAT_REGEXP_2 = /^\d{1,3}(\,\d{3})*(\.\d+)?$/; //  67,123.45
            var FLOAT_REGEXP_3 = /^\d{1,3}(\s\d{3})*(\,\d+)?$/; //  67 123,45
            var FLOAT_REGEXP_4 = /^\d{1,3}(\s\d{3})*(\.\d+)?$/; //  67 123.45
            var FLOAT_REGEXP_5 = /^\$?\d+(\.\d+)?$/;            //  67123.45
            var FLOAT_REGEXP_6 = /^\$?\d+(\,\d+)?$/;            //  67123,45

            if (typeof value === 'number')
                return value;
            if (typeof value !== 'string')
                return undefined;

            //Test for patterns and act accordingly.
            if (FLOAT_REGEXP_1.test(value)) {
                return parseFloat(value.replace(/[.]/g, '').replace(/[,]/g, '.'));
            } else if (FLOAT_REGEXP_2.test(value)) {
                return parseFloat(value.replace(/[,]/g, ''));
            } else if (FLOAT_REGEXP_3.test(value)) {
                return parseFloat(value.replace(/[\s]/g, '').replace(/[,]/g, '.'));
            } else if (FLOAT_REGEXP_4.test(value)) {
                return parseFloat(value.replace(/[\s]/g, ''));
            } else if (FLOAT_REGEXP_5.test(value)) {
                return parseFloat(value);
            } else if (FLOAT_REGEXP_6.test(value)) {
                return parseFloat(value.replace(/[,]/g, '.'));
            } else if (value === '') {
                return 0.0;
            } else {
                return undefined;
            }
        }
    });

    angular.module('currencyCalculator').directive('numberInput', ['$filter', function ($filter) {
        return {
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl) {
                //Prevent unwanted characters from being typed.
                ctrl.$parsers.unshift(function (viewValue) {
                    var digits = viewValue.replace(/[^0-9., ]/g, '');
                    if (digits !== viewValue) {
                        ctrl.$setViewValue(digits);
                        ctrl.$render();
                        viewValue = digits;
                    }

                    var parsedValue = $filter('localizedFloat')(viewValue);

                    if (parsedValue === undefined)
                        ctrl.$setValidity('float', false);
                    else
                        ctrl.$setValidity('float', true);

                    return parsedValue;
                });
            }
        }
    }]);
};
